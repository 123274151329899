export default function (isSupported, authContext, activeTab, firstEntrance) {

	function logoutNeeded() {
		if (isSupported) {
			const hasActiveTab = activeTab.isActiveTabAlive();
			return authContext.isLogged() && firstEntrance.didOccurred() && !hasActiveTab;
		}

		return false;
	}

	this.debugInfo = () => (
		`logoutNeeded: ${logoutNeeded()}, isActiveTabAlive: ${activeTab.isActiveTabAlive()}, firstEntrance: ${firstEntrance.didOccurred()}`
	);
	this.isLogoutNeeded = logoutNeeded;
}
