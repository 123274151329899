export default function (location) {

	function parseParams(query) {
		const result = {};
		query.split("&").forEach((part) => {
			const item = part.split("=");
			result[item[0]] = decodeURIComponent(item[1]);
		});
		return result;
	}

	function authResponseParams() {
		return parseParams(location.hash());
	}
	function clearResponseParams() {
		location.replaceHash(null);
	}

	this.parse = authResponseParams;
	this.clear = clearResponseParams;
}
