// This function must not be split into smaller functions, because it won't work when serialized into a text
// and passed as a Web Worker's body
export default function webWorkerForIntervalCreator() {
	const requestIdToIntervalId = {};

	onmessage = function onMessageInWorker(event) {
		const data = event.data;
		const name = data.name;
		const requestId = data.requestId;
		let time;

		if (Object.prototype.hasOwnProperty.call(data, "time")) {
			time = data.time;
		}

		if (name === "setInterval") {
			requestIdToIntervalId[requestId] = setInterval(() => {
				postMessage({
					requestId
				});
			}, time);
		} else if (name === "clearInterval") {
			if (Object.prototype.hasOwnProperty.call(requestIdToIntervalId, requestId)) {
				clearInterval(requestIdToIntervalId[requestId]);
				delete requestIdToIntervalId[requestId];
			}
		}
	};
}
