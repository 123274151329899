import randomId from "./randomId";

export default function (storage) {
	const idStorage = storage.foreverCrossTab("NexusBrowserId");

	function getBrowserId() {
		let browserId = idStorage.get();
		if (!browserId) {
			browserId = randomId.generate();
			idStorage.set(browserId);
		}

		return browserId;
	}

	this.get = getBrowserId;
}
