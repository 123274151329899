export default function (storeEventUntilFirstForwarder) {
	let storeEvents = storeEventUntilFirstForwarder || false;

	const listeners = {};
	const forwarders = [];
	const bufferedEvents = [];

	function listenersFor(name) {
		if (!listeners[name]) {
			listeners[name] = [];
		}
		return listeners[name];
	}

	this.broadcast = (name, details) => {
		if (storeEvents) {
			bufferedEvents.push({
				name,
				details
			});
		}

		listenersFor(name).forEach((listener) => {
			listener(name, details);
		});
		forwarders.forEach((forwarder) => {
			forwarder(name, details);
		});
	};

	this.on = (name, listenerCallback) => {
		listenersFor(name).push(listenerCallback);
		return {
			name,
			listener: listenerCallback
		};
	};

	this.off = (id) => {
		if (id && id.name && id.listener) {
			const listenersForEvent = listenersFor(id.name);
			const idx = listenersForEvent.indexOf(id.listener);
			if (idx > -1) {
				listenersForEvent.splice(idx, 1);
			}
		}
	};

	this.registerForwarder = (listenerCallback) => {
		forwarders.push(listenerCallback);
		if (storeEvents) {
			forwarders.forEach((forwarder) => {
				bufferedEvents.forEach((event) => {
					forwarder(event.name, event.details);
				});
			});
			storeEvents = false;
		}
	};
}
