function ReloginModalCtrl(modalInstance, authConfig, authContext, loginState, authLogger, location, eventBus, interval) {
	const currentLogin = loginState.startRelogin();
	const currentAccessToken = authContext.getAccessToken();
	let tokenRefreshedCheck;
	let userLoggedCheck;

	this.loginPageUrl = authConfig.loginUrlFor(currentLogin, location.absUrl());

	function close() {
		modalInstance.close();
		if (userLoggedCheck) {
			interval.cancel(userLoggedCheck);
		}
		if (tokenRefreshedCheck) {
			eventBus.off(tokenRefreshedCheck);
		}
	}

	function checkIfUserIsLogged() {
		if (authContext.isLogged() && currentAccessToken !== authContext.getAccessToken()) {
			authLogger.logInfo("Closing relogin modal since it seems that user is logged (maybe from different tab)");
			close();
			if (loginState.isCurrentLoginAbout(currentLogin)) {
				loginState.stopLogin();
			}
		}
	}

	tokenRefreshedCheck = eventBus.on("security:tokenRefreshed", close);
	userLoggedCheck = interval(checkIfUserIsLogged, 10 * 1000);
}

export default ReloginModalCtrl;
