import randomId from "./randomId";

export default function (storage) {
	const stateStorage = storage.crossTab("NexusLoginState");
	const nonceStorage = storage.crossTab("NexusLoginNonce");
	const startTimeStorage = storage.crossTab("NexusLoginStartTime");
	const methodStorage = storage.crossTab("NexusLoginMethod");

	function generateNonceId() {
		const state = randomId.generate();
		nonceStorage.set(state);
		return state;
	}

	function generateStateId() {
		const state = randomId.generate();
		stateStorage.set(state);
		return state;
	}

	function createStartTime() {
		const now = new Date().toISOString();
		startTimeStorage.set(now);
		return now;
	}

	function createLoginState(state, nonce, startTime, method) {
		return {
			state,
			nonce,
			startTime,
			inProgress: !!state,
			method,
			isRefresh: method === "refresh",
			isRelogin: method === "relogin",
			isLogin: method === "login"
		};
	}

	function generateLoginState(method) {
		const state = generateStateId();
		const nonce = generateNonceId();
		const startTime = createStartTime();
		methodStorage.set(method);

		return createLoginState(state, nonce, startTime, method);
	}

	function getLoginState() {
		const state = stateStorage.get();
		const nonce = nonceStorage.get();
		const startTime = startTimeStorage.get();
		const method = methodStorage.get();

		return createLoginState(state, nonce, startTime, method);
	}

	function clearLoginState() {
		stateStorage.set(null);
		nonceStorage.set(null);
		startTimeStorage.set(null);
		methodStorage.set(null);
	}

	function verifyStateAgainst(state) {
		const currentLogin = getLoginState();
		if (!currentLogin || !currentLogin.state) {
			return "There's no information about a login in progress";
		}
		if (!state || state !== currentLogin.state) {
			return `state returned by auth server is invalid: ${state} (expected: ${currentLogin.state})`;
		}
		return undefined;
	}

	function isInProgress() {
		return !!stateStorage.get();
	}
	function isCurrentLoginAbout(other) {
		const currentLogin = getLoginState();
		return currentLogin && other && currentLogin.state === other.state;
	}

	function startLogin() {
		return generateLoginState("login");
	}
	function startRelogin() {
		return generateLoginState("relogin");
	}
	function startRefresh() {
		return generateLoginState("refresh");
	}

	this.debugInfo = () => {
		const currentLogin = getLoginState();
		return `currentLogin isInProgress: ${currentLogin.isInProgress}, startTime: ${currentLogin.startTime},
			method: ${currentLogin.method}, nonce: ${currentLogin.nonce}, state: ${currentLogin.state}`;
	};
	this.startLogin = startLogin;
	this.startRefresh = startRefresh;
	this.startRelogin = startRelogin;
	this.isInProgress = isInProgress;
	this.isCurrentLoginAbout = isCurrentLoginAbout;
	this.currentLogin = getLoginState;
	this.stopLogin = clearLoginState;
	this.verifyStateAgainst = verifyStateAgainst;
}
