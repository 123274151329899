import WebWorkerIntervalSupplier from "./webWorkerIntervalSupplier";

function createIntervalSupplier() {
	try {
		return new WebWorkerIntervalSupplier(window);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error);
		return window;
	}
}

export default function () {
	const intervalSupplier = createIntervalSupplier();

	const interval = (fn, milliseconds) => {
		const id = intervalSupplier.setInterval(fn, milliseconds);
		return { id };
	};
	interval.cancel = (promise) => {
		if (promise) {
			intervalSupplier.clearInterval(promise.id);
		}
	};

	return interval;
}
