/* eslint-disable no-console */

import webWorkerForIntervalCreator from "./webWorkerForIntervalCreator";

const logPrefix = "WebWorker timer: ";

function createState() {
	return {
		requests: {},
		lastRequestId: 100000, // To make sure that we don't reuse existing timers ids (the page can load some timers before the worker kicks in).
		maxRequestId: Number.MAX_SAFE_INTEGER,
		getNextRequestId() {
			do {
				if (this.lastRequestId === this.maxRequestId) {
					this.lastRequestId = 0;
				} else {
					this.lastRequestId++;
				}
			} while (Object.prototype.hasOwnProperty.call(this.requests, this.lastRequestId));
			return this.lastRequestId;
		}

	};
}

function createWebWorker(window, state) {
	if (typeof (window.Worker) !== "undefined") {
		try {
			return setUpWebWorkerTimers(window, state);
		} catch (error) {
			console.log(error);
			throw new Error(`${logPrefix}Initialisation of Web Worker failed`);
		}
	}
	throw new Error(`${logPrefix}Initialisation failed - HTML5 Web Worker is not supported`);
}

function setUpWebWorkerTimers(window, state) {
	const workerBlob = new Blob([`(${webWorkerForIntervalCreator.toString()})()`], {
		type: "text/javascript"
	});
	const workerObjectUrl = URL.createObjectURL(workerBlob);
	const worker = new window.Worker(workerObjectUrl);

	worker.onmessage = event => onMessageFromWorker(window, event, state);
	worker.onerror = function onError(event) {
		console.log(event);
	};

	return worker;
}

function onMessageFromWorker(window, event, state) {
	const data = event.data;
	const requestId = data.requestId;
	let request;
	let parameters;
	let callback;

	if (Object.prototype.hasOwnProperty.call(state.requests, requestId)) {
		request = state.requests[requestId];
		callback = request.callback;
		parameters = request.parameters;
	}

	if (typeof (callback) === "function") {
		callback.apply(window, parameters);
	}
}

export default function webWorkerIntervalSupplier(window) {
	this.state = createState();
	this.worker = createWebWorker(window, this.state);

	this.setInterval = (callback, time /* , parameters */) => {
		const requestId = this.state.getNextRequestId();
		this.state.requests[requestId] = {
			callback,
			// eslint-disable-next-line prefer-rest-params
			parameters: Array.prototype.slice.call(arguments, 2)
		};
		this.worker.postMessage({
			name: "setInterval", requestId, time
		});
		return requestId;
	};
	this.clearInterval = (requestId) => {
		if (Object.prototype.hasOwnProperty.call(this.state.requests, requestId)) {
			delete this.state.requests[requestId];
			this.worker.postMessage({
				name: "clearInterval", requestId
			});
		}
	};
}
