import translations from "i18n/translations";

const styles = ".inactivity-banner { " +
		"display: none;" +
		"position: fixed;" +
		"left: 0;" +
		"top: 0;" +
		"width: 100%;" +
		"z-index: 100000;" +
		"padding: 10px;" +
		"background: #f3df81;" +
		"color: #000;" +
		"text-align: center;" +
		"font-weight: bold;" +
		"border-bottom: 1px solid #debb16;" +
	"}" +

	".inactivity-banner.open {" +
		"display:block" +
	"}";

export default function (eventBus, document) {
	function warningMessage(countdownSecs) {
		const minutes = Math.floor(countdownSecs / 60) + 1;
		// msg: "you will be logged out in less than X minutes"
		const template = translations.get("common_inactivity_warning");
		return template.replace("{{minutes}}", minutes);
	}

	function addBannerToBody() {
		document.querySelector("head").insertAdjacentHTML("beforeend", `<style type="text/css">${styles}</style>`);
		document.querySelector("body").insertAdjacentHTML("afterbegin", "<div class=\"inactivity-banner\"></div>");
	}

	function startListeners() {
		eventBus.on("security:IdleWarn", (e, countdown) => {
			const inactivityBanner = document.querySelector(".inactivity-banner");
			inactivityBanner.classList.add("open");
			inactivityBanner.innerHTML = warningMessage(countdown);
		});

		eventBus.on("security:IdleEnd", () => {
			const inactivityBanner = document.querySelector(".inactivity-banner");
			inactivityBanner.classList.remove("open");
		});
	}

	this.init = () => {
		addBannerToBody();
		startListeners();
	};
}
