import utcTime from "support/utcTime";
import randomId from "./randomId";

/* Simple algorithm which selects one tab which will do processing. */

const pingEverySec = 1;
const takeOverSec = 7;
const deadAfterSec = 10;

export default function (storage, interval, log) {
	const thisTabIdStorage = storage.singleTab("NexusThisTabId", true);
	const activeTabStorage = storage.crossTab("NexusActiveTab");
	let currentTabId;

	function aliveStorageFor(tabId) {
		return storage.crossTab(`NexusTabAlive.${tabId}`);
	}

	function wasActiveTabAliveDuring(alivePeriod) {
		const activeTab = activeTabStorage.get();
		if (activeTab) {
			const lastPing = aliveStorageFor(activeTab).getInt();
			return utcTime.now() - lastPing < alivePeriod;
		}
		return false;
	}

	function start() {
		currentTabId = thisTabIdStorage.get() || randomId.generate();
		thisTabIdStorage.set(currentTabId);
		const aliveStorage = aliveStorageFor(currentTabId);

		function takeOver() {
			log.info(`Taking over. Active tab is: ${currentTabId}`);
			activeTabStorage.set(currentTabId);
		}
		function ping() {
			aliveStorage.set(utcTime.now());
		}

		function process() {
			ping();
			if (!wasActiveTabAliveDuring(takeOverSec)) {
				takeOver();
			}
		}

		interval(process, pingEverySec * 1000);
	}

	function isTabActive() {
		const activeTabId = activeTabStorage.get();
		return currentTabId === activeTabId;
	}

	this.track = start;
	this.isTabActive = isTabActive;
	this.isActiveTabAlive = () => (
		wasActiveTabAliveDuring(deadAfterSec)
	);
}
