
export default function (log, clearExclusions = []) {
	const clearExclusionRules = [];
	addClearExclusionRules(clearExclusions);

	// Supported config syntax is a string array containing regexps: ["^foo$", "^bar.*", "Baz"]
	function addClearExclusionRules(regexpList) {
		if (Array.isArray(regexpList)) {
			regexpList.forEach(regexpString => addClearExclusionRuleRegex(regexpString));
		}
	}

	function addClearExclusionRuleExact(rule) {
		if (rule && typeof rule === "string") {
			clearExclusionRules.push(rule);
		}
	}

	function addClearExclusionRuleRegex(regexpString) {
		if (regexpString && typeof regexpString === "string") {
			try {
				clearExclusionRules.push(new RegExp(regexpString));
			} catch (e) {
				// Rather skip a failed exclusion than throw on config error
				log.warn(`Error parsing clear storage exclusion regexp: ${regexpString}`);
			}
		}
	}

	function isExcludedFromClear(key) {
		return clearExclusionRules.some((rule) => {
			if (typeof rule === "string") {
				return key === rule;
			}
			// Regexp
			return rule.test(key);
		});
	}

	function supports(storage) {
		try {
			return storage && storage !== null;
		} catch (e) {
			return false;
		}
	}

	function storageHolder(storage, key) {
		return {
			get() {
				const value = storage.getItem(key);
				return value !== null ? value : undefined;
			},
			getInt() {
				const value = storage.getItem(key);
				const intValue = parseInt(value, 10);
				// eslint-disable-next-line no-restricted-globals
				return intValue === null || intValue === undefined || isNaN(intValue) ? undefined : intValue;
			},
			set(value) {
				if (value === null || value === undefined) {
					storage.removeItem(key);
				} else {
					storage.setItem(key, value);
				}
			}
		};
	}

	function safeAccess(storage, fn) {
		if (supports(storage)) {
			return fn();
		}
		throw new Error("Local storage is not supported.");
	}

	function constructorFn(storage, key, dontClear) {
		if (dontClear) {
			addClearExclusionRuleExact(key);
		}
		return safeAccess(storage, () => storageHolder(storage, key));
	}

	function clear(storage) {
		safeAccess(storage, () => {
			const saved = [];
			for (let i = 0; i < storage.length; i++) {
				const key = storage.key(i);
				if (isExcludedFromClear(key)) {
					saved.push({
						key,
						value: storageHolder(storage, key).get()
					});
				}
			}
			storage.clear();
			saved.forEach((entry) => {
				storageHolder(storage, entry.key).set(entry.value);
			});
		});
	}

	function clearAll() {
		clear(localStorage);
		clear(sessionStorage);
	}

	this.crossTab = key => constructorFn(localStorage, key);
	this.singleTab = key => constructorFn(sessionStorage, key);
	this.foreverCrossTab = key => constructorFn(localStorage, key, true);
	this.foreverSingleTab = key => constructorFn(sessionStorage, key, true);
	this.clear = clearAll;
}
