import Modals from "support/modals";
import translations from "i18n/translations";
import ReloginModalCtrl from "./reloginModalCtrl";

const html = iframeSrc => (
	/* eslint-disable prefer-template */
	"<div class='login-iframe' style='height: 550px;'>" +
		"<iframe src='" + iframeSrc + "' style='width: 100%; height: 100%; border: 0;'>" +
		"</iframe>" +
	"</div>"
);

export default function (authConfig, authContext, loginState, authLogger, location, interval, eventBus, document) {
	const modals = new Modals(document);
	const title = translations.get("common_relogin_title");

	this.open = (onClose) => {
		modals.open(title, (modalInstanceFn) => {
			const ctrl = new ReloginModalCtrl(modalInstanceFn(onClose), authConfig, authContext, loginState, authLogger, location, eventBus, interval);
			return html(ctrl.loginPageUrl);
		});
	};
}
