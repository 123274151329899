/* eslint-disable prefer-spread,prefer-rest-params */
export default function (windowConsole, isDebug) {
	return {
		info() {
			windowConsole.info.apply(windowConsole, arguments);
		},
		warn() {
			windowConsole.warn.apply(windowConsole, arguments);
		},
		error() {
			windowConsole.error.apply(windowConsole, arguments);
		},
		debug() {
			if (isDebug) {
				windowConsole.log.apply(windowConsole, arguments);
			}
		}
	};
}
