import jsRsaSign from "oauth/jsrsasign";

export default function (cookies, previousUserExpiration) {
	return {
		store(username) {
			if (username) {
				const usernameHash = jsRsaSign.crypto.sha256(username);
				cookies.set("previousUser", usernameHash, previousUserExpiration);
			}
		},
		hasDifferentUser(username) {
			if (username) {
				const currentUserHash = jsRsaSign.crypto.sha256(username);
				const previousUserHash = cookies.get("previousUser");

				return !!previousUserHash && currentUserHash !== previousUserHash;
			}
			return false;
		}
	};
}
