import da from "./messages.da";

let lang;
const messages = { da };
export default {
	get(key) {
		return messages[lang][key];
	},
	setLang(userlang) {
		lang = userlang;
	}
};
