import ExternalSecurity from "./externalSecurity";
import DefaultSecurity from "./defaultSecurity";
import UserAgent from "./support/userAgent";

export default function (userConfig, extraConfig) {

	function merge(dst, src) {
		if (src) {
			Object.keys(src).forEach((key) => {
				// eslint-disable-next-line no-param-reassign
				dst[key] = src[key];
			});
		}
		return dst;
	}

	const defaultConfig = {
		clientId: "nexus",
		debug: false,

		/* Session will expire after 1h + 5min of user inactivity. Extra 5 minutes added to not overlap with token refreshing */
		session_timeout: 65 * 60,
		/* Warning about idle session will be shown 5min before logout */
		warning_timeout: 5 * 60,

		/* Refresh token 1 hour before its expiration.
          It is similar to session_timeout, so when user turns off the device (and token refreshing won't work)
          for less than 1h, he still should have valid token, and able to work correctly with the system. */
		refreshToken_secBeforeExpiration: 60 * 60,
		/* If a token refresh takes more than 2min, treat it as failed. Quite pessimistic setting. */
		refreshToken_timeout: 2 * 60,
		/* 30s - just before token expiration we are trying to relogin using a modal */
		relogin_secBeforeExpiration: 30,

		/* All events are propagated to all listeners and forgotten */
		keepEventsForForwarder: false,

		/* How long in minutes hash of previous user should be valid. Used for determining if redirect uri
         * should be followed. */
		previousUserExpiration: 480,

		/**
		 * Path which we will hit if redirect uri should not be followed
		 */
		defaultWelcomePath: "welcome",

		/* No external error tracking */
		trackErrorFn() {}
	};

	const config = merge(merge(merge({}, defaultConfig), userConfig), extraConfig);
	const securityImpl = externalSecurityEnabled() ? new ExternalSecurity(config) : new DefaultSecurity(config);

	function externalSecurityEnabled() {
		if (!userConfig || typeof userConfig === "undefined") {
			return false;
		}
		if (userConfig.userAgent_for_externalSecurity) {
			return UserAgent.contains(userConfig.userAgent_for_externalSecurity);
		}
		return false;
	}

	this.startApp = () => securityImpl.startApp();

	this.registerEventForwarder = forwarder => securityImpl.registerEventForwarder(forwarder);

	this.logout = reason => securityImpl.logout(reason);

	this.context = securityImpl.context;

	this.referrerStore = securityImpl.referrerStore;
}
