export default function (authContext, authActions, interval, loginState, log) {
	this.track = () => {
		let logoutExpiredSessionsPromise;
		function logoutExpiredSessions() {
			if (authContext.isSessionExpired()) {
				log.info("Cleanup of expired tokens.");
				interval.cancel(logoutExpiredSessionsPromise);
				const stateOfLogin = loginState.currentLogin();
				if (stateOfLogin.inProgress && stateOfLogin.isLogin) {
					authActions.redirectToLoginPageReuseCurrentLogin();
				} else {
					authActions.redirectToLogoutPageAndReturnToCurrentPage(false);
				}
			}
		}

		interval(logoutExpiredSessions, 3 * 1000);
	};
}
