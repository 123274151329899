/* eslint-disable no-param-reassign */
export default function (globalLocation, globalHistory) {
	function without1Char(input) {
		return input.substr(1);
	}

	return {
		replace(absUrl) {
			globalLocation.href = absUrl;
		},
		replaceHistoryPath(path) {
			const link = `${this.origin()}/${path}`;
			globalHistory.pushState({ urlPath: link }, "", link);
		},
		replaceHash(hash) {
			if (hash) {
				globalLocation.hash = hash;
			} else {
				globalLocation.hash = "";
			}
		},
		absUrl() {
			return globalLocation.href;
		},
		path() {
			return globalLocation.pathname;
		},
		hash() {
			return without1Char(globalLocation.hash);
		},
		url() {
			const fullUrl = globalLocation.href;
			const host = globalLocation.origin;
			return fullUrl.substr(host.length, fullUrl.length - host.length);
		},
		origin() {
			// window.location.origin is not IE safe
			const port = globalLocation.port ? `:${globalLocation.port}` : "";
			return `${globalLocation.protocol}//${globalLocation.hostname}${port}`;
		},
		query() {
			return without1Char(globalLocation.search);
		},
		reload() {
			globalLocation.reload();
		}
	};
}
