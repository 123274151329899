import AuthResponseParams from "./authResponseParams";

export default function (tokens, authContext, loginState, location, eventBus, authLogger) {
	const responseParams = new AuthResponseParams(location);

	this.isReturningFromLoginPage = () => {
		const authResponse = responseParams.parse();
		const hasAccessToken = !!authResponse.access_token;

		return loginState.isInProgress() && hasAccessToken;
	};

	this.processUrl = () => {
		if (loginState.isInProgress()) {
			const authResponse = responseParams.parse();
			const accessToken = authResponse.access_token;
			if (accessToken) {
				responseParams.clear();
				const currentLogin = loginState.currentLogin();

				const idTokenError = tokens.verifyIdToken(authResponse.id_token, currentLogin.nonce);
				const accessTokenError = tokens.verifyAccessToken(accessToken);
				const stateError = loginState.verifyStateAgainst(authResponse.state);

				if (!idTokenError && !accessTokenError && !stateError) {
					authLogger.logInfo(`Logged in with correct id_token and access_token (expires in ${authResponse.expires_in}s).`);
					authContext.login(tokens.parseToken(accessToken));
					eventBus.broadcast("security:userLoggedIn", {
						username: authContext.username(),
						login: currentLogin
					});
					loginState.stopLogin();
				} else {
					authLogger.logFailedLogin(`${"URL contains access_token but the request is invalid. " +
						"Access token issues: '"}${accessTokenError || "-"}'. ` +
						`Id token issues: '${idTokenError || "-"}'. ` +
						`State issues: '${stateError || "-"}'. `, currentLogin);
				}
			}
		}
	};
}
