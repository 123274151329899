const styles = "" +
	".security-modal-backdrop {" +
		"position: fixed;" +
		"top: 0;" +
		"right: 0;" +
		"bottom: 0;" +
		"left: 0;" +
		"z-index: 1040;" +
		"opacity: .8;" +
		"background-color: #000;" +
	"}" +
	".security-modal {" +
		"position: fixed;" +
		"top: 0;" +
		"right: 0;" +
		"bottom: 0;" +
		"left: 0;" +
		"z-index: 1050;" +
	"}" +
	".security-modal-title {" +
		"color: #155758;" +
		"font-size: 18px;" +
		"letter-spacing: 0.02em;" +
	"}" +
	".security-modal-header {" +
		"min-height: 50px;" +
		"padding: 10px 20px 0 20px;" +
	"}" +
	".security-modal-body {" +
		"overflow: visible;" +
		"width: 100%;" +
		"min-height: 230px;" +
		"padding: 0;" +
	"}" +
	".security-modal-content {" +
		"background-color: #fff;" +
		"width: auto;" +
		"margin: auto;" +
	"}" +
	"@media (min-width: 768px) { " +
		".security-modal-content {" +
			"width: 600px;" +
			"margin: 100px auto 50px;" +
	"}}";

const stylesHtml = `<style id="security-modal-styles" type="text/css">${styles}</style>`;
const html = (title, body) => (
	`${"<div class='security-modal'>" +
		"<div class='security-modal-content'>" +
			"<div class='security-modal-header'>" +
				"<h3 class='security-modal-title'>"}${title}</h3>` +
			`</div>` +
			`<div class='security-modal-body'>${body}</div>` +
		`</div>` +
	`</div>` +
	`<div class='security-modal-backdrop'></div>`
);

export default function (document) {
	function $(selector) {
		return document.querySelector(selector);
	}
	function remove(parent, child) {
		if (child) {
			parent.removeChild(child);
		}
	}

	function close() {
		const head = $("head");
		const body = $("body");
		remove(head, $("#security-modal-styles"));
		remove(body, $(".security-modal"));
		remove(body, $(".security-modal-backdrop"));
	}

	this.open = (title, bodyProducer) => {
		const modalInstanceFn = onClose => ({
			close() {
				onClose();
				close();
			}
		});
		$("head").insertAdjacentHTML("beforeend", stylesHtml);
		$("body").insertAdjacentHTML("beforeend", html(title, bodyProducer(modalInstanceFn)));
	};
}
