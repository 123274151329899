export default function (eventBus, window) {
	this.start = () => {
		eventBus.on("security:userLoggedIn", () => {
			if (window.frameElement) {
				window.parent.postMessage({ tokenRefreshed: true }, "*");
			}
		});
		window.addEventListener("message", (event) => {
			if (event.data && event.data.loginPageOpened) {
				eventBus.broadcast("security:loginPageOpened");
			}
			if (event.data && event.data.tokenRefreshed) {
				eventBus.broadcast("security:tokenRefreshed");
			}
		}, false);
	};
}
