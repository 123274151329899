export default function (window) {

	const timeout = (fn, milliseconds) => {
		const id = window.setTimeout(fn, milliseconds);
		return { id };
	};

	timeout.cancel = (promise) => {
		if (promise) {
			window.clearTimeout(promise.id);
		}
	};

	return timeout;
}
