import randomId from "./randomId";

export default function (storage) {
	const trackerStorage = storage.foreverSingleTab("FirstNexusEntranceTracker");

	let firstEntrance;
	function checkOnStartup() {
		firstEntrance = trackerStorage.get() === undefined;
		trackerStorage.set(randomId.generate());
	}

	this.checkOnStartup = checkOnStartup;
	this.didOccurred = () => (
		!!firstEntrance
	);
}
