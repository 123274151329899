/* eslint-disable no-param-reassign */
export default function (globalDocument) {
	return {
		set(cname, cvalue, expMinutes) {
			const d = new Date();
			d.setTime(d.getTime() + (expMinutes * 60 * 1000));
			const expires = `expires=${d.toUTCString()}`;
			globalDocument.cookie = `${cname}=${cvalue};${expires};path=/`;
		},
		get(cname) {
			const name = `${cname}=`;
			const decodedCookie = decodeURIComponent(globalDocument.cookie);
			const cookieArray = decodedCookie.split(";");
			const cookieRow = cookieArray
				.map((element) => {
					let el = element;
					while (el.charAt(0) === " ") {
						el = el.substring(1);
					}
					return el;
				})
				.find(element => element.indexOf(name) === 0);
			if (cookieRow) {
				return cookieRow.substring(name.length, cookieRow.length);
			}
			return "";
		}
	};
}
