export default function (authContext, interval, location, log) {
	let usernameLastLogged;

	function detectUsernameChange() {
		if (authContext.isLogged()) {
			const currentUsername = authContext.username();
			if (usernameLastLogged && currentUsername && usernameLastLogged !== currentUsername) {
				log.warn(`Username is different than it was (${usernameLastLogged}!=${currentUsername}). Reloading.`);
				location.reload();
			}

			if (currentUsername) {
				usernameLastLogged = currentUsername;
			}
		}
	}

	this.track = () => {
		interval(detectUsernameChange, 10 * 1000);
	};
}
