import Tokens from "./oauth/tokens";
import Logger from "./support/consoleLogger";
import AuthConfig from "./authConfig";
import BrowserId from "./state/browserId";
import Storage from "./state/storage";

export default function (config) {

	const log = new Logger(window.console, config.debug);
	const storage = new Storage(log);
	const browserId = new BrowserId(storage);
	const authConfig = new AuthConfig(config, browserId);
	const tokens = new Tokens(authConfig);

	let token;

	const readTokenFromStorage = () => {
		const providedToken = sessionStorage.getItem("AccessToken");

		if (providedToken && (!token || providedToken !== token.raw)) {
			log.debug("Provided token:", providedToken);
			token = tokens.parseToken(providedToken);
			return true;
		}

		return false;
	};

	const authContext = {
		getAccessToken() {
			readTokenFromStorage();
			return token.raw;
		},
		isTokenNotExpired() {
			return !this.hasExpiredToken();
		},
		hasExpiredToken() {
			return false;
		},
		isSessionExpired() {
			return false;
		},
		isSessionNotExpired() {
			return !this.isSessionExpired();
		},
		isSessionOn() {
			return true;
		},
		login() {
		},
		logout() {
			log.debug("Logout triggered");
			window.postMessage(JSON.stringify({ msgType: "logoutEvent" }));
		},
		isLogged() {
			return true;
		},
		isNotLogged() {
			return !this.isLogged();
		},
		username() {
			return token.payload.sub;
		},
		usernameIsChangedAfterLogin() {
		},
		debugInfo() {
			return `token: ${this.token}`;
		}
	};

	this.startApp = () => readTokenFromStorage();

	this.registerEventForwarder = () => {
	};
	this.logout = (reason) => {
		log.debug("Logout triggered");
		window.postMessage(JSON.stringify({ msgType: "logoutEvent", payload: reason }));
	};
	this.context = authContext;
	this.referrerStore = {
		// eslint-disable-next-line no-unused-vars
		getReferrer: (host) => { throw new Error("Referrer Store not available"); }
	};
}
