function AlternativeStorage() {
	const storageMap = {};
	this.setItem = (key, value) => {
		storageMap[key] = value;
	};

	this.getItem = (key) => {
		if (typeof storageMap[key] !== "undefined") {
			return storageMap[key];
		}
		return null;
	};

	this.removeItem = (key) => {
		storageMap[key] = undefined;
	};
}

// Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
// throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
// to avoid the entire page breaking, without having to do a check at each usage of Storage.
function getStorage(windowLocalStorage) {
	try {
		windowLocalStorage.setItem("ngIdleStorage", "");
		windowLocalStorage.removeItem("ngIdleStorage");

		return windowLocalStorage;
	} catch (err) {
		return new AlternativeStorage();
	}
}

function fromJson(json) {
	return typeof json === "string" ? JSON.parse(json) : json;
}

function toJson(obj) {
	return JSON.stringify(obj);
}

export default function (windowLocalStorage) {
	const storage = getStorage(windowLocalStorage);
	return {
		set(key, value) {
			storage.setItem(`ngIdle.${key}`, toJson(value));
		},
		get(key) {
			return fromJson(storage.getItem(`ngIdle.${key}`));
		},
		remove(key) {
			storage.removeItem(`ngIdle.${key}`);
		},
		_wrapped() {
			return storage;
		}
	};
}
