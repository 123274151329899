import utcTime from "support/utcTime";
import jsrsasign from "./jsrsasign";

function newToken(sJWT) {
	const a = sJWT.split(".");
	const uHeader = a[0];
	const uPayload = a[1];

	const pHeader = jsrsasign.jws.readSafeJSONString(jsrsasign.b64utoutf8(uHeader));
	const pPayload = jsrsasign.jws.readSafeJSONString(jsrsasign.b64utoutf8(uPayload));

	return {
		raw: sJWT,
		header: pHeader,
		payload: pPayload,
		isNotExpired: () => {
			const now = utcTime.now();
			return pPayload.exp !== undefined && typeof pPayload.exp === "number" && pPayload.exp > now;
		},
		willExpireIn: (secs) => {
			const now = utcTime.now();
			return pPayload.exp !== undefined && typeof pPayload.exp === "number" && pPayload.exp < now + secs;
		}
	};
}

function checkAccessToken(sJWT, keys, acceptField) {
	const jwt = newToken(sJWT);

	if (jwt.payload.iss !== undefined && typeof acceptField.iss === "object") {
		if (!jsrsasign.jws.inArray(jwt.payload.iss, acceptField.iss)) {
			return `'iss' is not accepted. It should be: ${acceptField.iss}`;
		}
	}

	if (jwt.payload.sub !== undefined && typeof acceptField.sub === "object") {
		if (!jsrsasign.jws.inArray(jwt.payload.sub, acceptField.sub)) {
			return `'sub' is not accepted. It should be: ${acceptField.sub}`;
		}
	}

	if (jwt.payload.aud !== undefined && typeof acceptField.aud === "object") {
		if (typeof jwt.payload.aud === "string" && !jsrsasign.jws.inArray(jwt.payload.aud, acceptField.aud)) {
			return `'aud' is not accepted. It should be: ${acceptField.aud}`;
		} else if (typeof jwt.payload.aud === "object" && !jsrsasign.jws.includedArray(jwt.payload.aud, acceptField.aud)) {
			return `'aud' is not accepted. It should be: ${acceptField.aud}`;
		}
	}

	let now = utcTime.now();
	if (acceptField.verifyAt !== undefined && typeof acceptField.verifyAt === "number") {
		now = acceptField.verifyAt;
	}

	if (jwt.payload.exp !== undefined && typeof jwt.payload.exp === "number" && jwt.payload.exp < now) {
		return "Token is expired";
	}

	if (!isSignatureValid(sJWT, keys, acceptField.alg)) {
		return "Token signature is invalid";
	}

	return undefined;
}

function checkIdToken(sJWT, keys, acceptField) {
	const jwt = newToken(sJWT);

	// 4. algorithm ('alg' in header) check
	if (jwt.header.alg === undefined) {
		return "'alg' is not specified in the header.";
	} else if (acceptField.alg === undefined) {
		return "acceptField.alg shall be specified";
	} else if (!jsrsasign.jws.inArray(jwt.header.alg, acceptField.alg)) {
		return `'alg' is not accepted for: ${acceptField.alg}`;
	}

	// 5. issuer ('iss' in payload) check
	if (jwt.payload.iss !== undefined && typeof acceptField.iss === "object") {
		if (!jsrsasign.jws.inArray(jwt.payload.iss, acceptField.iss)) {
			return `'iss' is not accepted. It should be: ${acceptField.iss}`;
		}
	}

	// 6. subject ('sub' in payload) check
	if (jwt.payload.sub !== undefined && typeof acceptField.sub === "object") {
		if (!jsrsasign.jws.inArray(jwt.payload.sub, acceptField.sub)) {
			return `'sub' is not accepted. It should be: ${acceptField.sub}`;
		}
	}

	// 7. audience ('aud' in payload) check
	if (jwt.payload.aud !== undefined && typeof acceptField.aud === "object") {
		if (typeof jwt.payload.aud === "string" && !jsrsasign.jws.inArray(jwt.payload.aud, acceptField.aud)) {
			return `'aud' is not accepted. It should be: ${acceptField.aud}`;
		} else if (typeof jwt.payload.aud === "object" && !jsrsasign.jws.includedArray(jwt.payload.aud, acceptField.aud)) {
			return `'aud' is not accepted. It should be: ${acceptField.aud}`;
		}
	}

	if (jwt.payload.nonce === undefined) {
		return "'nonce' is not specified in the payload of jwt.";
	} else if (jwt.payload.nonce !== acceptField.nonce) {
		return `'nonce' is not accepted. It should be: ${acceptField.nonce}`;
	}

	// 8. time validity (nbf < now < exp) && (iat <= now)
	let now = utcTime.now();
	if (acceptField.verifyAt !== undefined && typeof acceptField.verifyAt === "number") {
		now = acceptField.verifyAt;
	}

	// 8.1 expired time 'exp' check
	if (jwt.payload.exp !== undefined && typeof jwt.payload.exp === "number" && jwt.payload.exp < now) {
		return `Token is expired. Exp date: ${jwt.payload.exp}. Now: ${now}`;
	}

	// 10 JWS signature check
	if (!isSignatureValid(sJWT, keys, acceptField.alg)) {
		return "Token signature is invalid";
	}

	// 11 passed all check
	return undefined;
}

function isSignatureValid(sJWT, keys, alg) {
	for (let i = 0; i < keys.length; i++) {
		if (jsrsasign.jws.verify(sJWT, keys[i], alg)) {
			return true;
		}
	}
	return false;
}

function isNotExpired(sJWT) {
	if (sJWT) {
		const token = newToken(sJWT);
		return token.isNotExpired();
	}
}

function willExpireIn(sJWT, secs) {
	if (sJWT) {
		const token = newToken(sJWT);
		return token.willExpireIn(secs);
	}
}

function verifyAccessToken(sJWT, keys, acceptField) {
	if (!sJWT) {
		return "access_token missing";
	}
	try {
		return checkAccessToken(sJWT, keys, acceptField);
	} catch (e) {
		return `access_token validation error${e}`;
	}
}

function verifyIdToken(sJWT, keys, acceptField) {
	if (!sJWT) {
		return "id_token missing";
	}
	try {
		return checkIdToken(sJWT, keys, acceptField);
	} catch (e) {
		return `id_token validation error${e}`;
	}
}

function loadPublicKeys(authConfig) {
	const result = [];

	const publicKeysRaw = loadRawKeys(authConfig);
	for (let i = 0; i < publicKeysRaw.length; i++) {
		const publicKeyRaw = publicKeysRaw[i];
		result.push(loadPublicKey(publicKeyRaw));
	}
	return result;
}

function loadRawKeys(authConfig) {
	let publicKeysRaw = [authConfig.publicKey];
	if (authConfig.secondaryPublicKeys !== undefined && authConfig.secondaryPublicKeys) {
		const secondaryKeysArray = authConfig.secondaryPublicKeys.split(",");
		publicKeysRaw = publicKeysRaw.concat(secondaryKeysArray);
	}
	return publicKeysRaw;
}

function loadPublicKey(publicKeyRaw) {
	try {
		return jsrsasign.keyutil.getKey(publicKeyRaw);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(`Error during loading public key. Contents: "${publicKeyRaw}". Error: `, e);
		throw e;
	}
}

export default function (authConfig) {
	let keys;
	const loadPublicKeysIfNeeded = () => {
		if (!keys) {
			keys = loadPublicKeys(authConfig);
		}
	};

	return {
		parseToken: newToken,
		verifyIdToken(jwt, nonce, verifyAt) {
			loadPublicKeysIfNeeded();
			return verifyIdToken(jwt, keys, {
				alg: ["RS256"],
				iss: authConfig.issuers,
				aud: [authConfig.clientId],
				nonce,
				verifyAt
			});
		},
		isCorrectIdToken(jwt, nonce, verifyAt) {
			return !this.verifyIdToken(jwt, nonce, verifyAt);
		},
		verifyAccessToken(jwt, verifyAt) {
			loadPublicKeysIfNeeded();
			return verifyAccessToken(jwt, keys, {
				iss: authConfig.issuers,
				aud: [authConfig.clientId],
				verifyAt
			});
		},
		isCorrectAccessToken(jwt, verifyAt) {
			return !this.verifyAccessToken(jwt, verifyAt);
		},
		isNotExpired,
		willExpireIn
	};
}
