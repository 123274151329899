import idleProvider from "./idleService";

export default function (authConfig, eventBus, interval) {
	let idleService;
	this.track = () => {
		idleService = idleProvider
			.idle(authConfig.sessionTimeout - authConfig.warningTimeout)
			.interrupt("keydown mousedown touchstart")
			.timeout(authConfig.warningTimeout)
			.build(eventBus, interval);

		idleService.watch();
	};

	this.isSessionExpired = () => (idleService ? idleService.isExpired() : false);

	this.newActivity = () => {
		idleService.watch();
	};
}
