import Logger from "support/consoleLogger";

export default function (config, document, storage) {
	const log = new Logger(window.console, config.debug);
	const referrerStorage = storage.crossTab("ReferrerList");

	this.setCurrentReferrer = () => {
		const referrer = document.referrer;
		if (referrer) {
			log.debug(`Referrer: ${referrer}`);

			const a = document.createElement("a");
			a.href = referrer;
			const hostname = a.hostname;

			const referrerList = referrerStorage.get() ? JSON.parse(referrerStorage.get()) : {};
			referrerList[hostname] = referrer;

			referrerStorage.set(JSON.stringify(referrerList));
		}
	};

	this.getReferrer = (host) => {
		const referrerList = referrerStorage.get() ? JSON.parse(referrerStorage.get()) : {};
		return referrerList[host];
	};

	this.api = {
		getReferrer: this.getReferrer
	};
}
