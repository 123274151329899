import LocalStorage from "./localStorage";

let storage = new LocalStorage(window.localStorage);
const options = {
	idle: 20 * 60, // in seconds (default is 20min)
	timeout: 30, // in seconds (default is 30sec)
	autoResume: "idle", // lets events automatically resume (unsets idle state/resets warning)
	interrupt: "mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll",
	windowInterrupt: null
};

const builder = {};
function isNumber(value) {
	return typeof value === "number";
}

/**
 *  Sets the number of seconds a user can be idle before they are considered timed out.
 *  @param {Number|Boolean} seconds A positive number representing seconds OR 0 or false to disable this feature.
 */
builder.timeout = (seconds) => {
	if (seconds === false) {
		options.timeout = 0;
	} else if (isNumber(seconds) && seconds >= 0) {
		options.timeout = seconds;
	} else {
		throw new Error("Timeout must be zero or false to disable the feature, or a positive integer (in seconds) to enable it.");
	}
	return builder;
};
const setTimeout = builder.timeout;

builder.storage = (windowLocalStorage) => {
	storage = new LocalStorage(windowLocalStorage);
	return builder;
};

builder.interrupt = (events) => {
	options.interrupt = events;
	return builder;
};

builder.windowInterrupt = (events) => {
	options.windowInterrupt = events;
	return builder;
};

builder.idle = (seconds) => {
	if (seconds <= 0) { throw new Error("Idle must be a value in seconds, greater than 0."); }

	options.idle = seconds;
	return builder;
};
const setIdle = builder.idle;

builder.autoResume = (value) => {
	if (value === true) {
		options.autoResume = "idle";
	} else if (value === false) {
		options.autoResume = "off";
	} else {
		options.autoResume = value;
	}
	return builder;
};

builder.build = (eventBus, interval) => {
	const state = {
		idle: null,
		timeout: null,
		idling: false,
		running: false,
		countdown: null
	};
	const id = new Date().getTime();

	function toggleState() {
		state.idling = !state.idling;
		const name = state.idling ? "security:IdleStart" : "security:IdleEnd";

		if (state.idling) {
			eventBus.broadcast(name);
			if (options.timeout) {
				state.countdown = options.timeout;
				countdown();
				state.timeout = interval(countdown, 1000, options.timeout, false);
			}
		} else {
			eventBus.broadcast(name);
		}

		interval.cancel(state.idle);
	}

	function countdown() {
		// check not called when no longer idling
		// possible with multiple tabs
		if (!state.idling) {
			return;
		}

		// countdown has expired, so signal timeout
		if (state.countdown <= 0) {
			timeout();
			return;
		}

		// countdown hasn't reached zero, so warn and decrement
		eventBus.broadcast("security:IdleWarn", state.countdown);
		state.countdown--;
	}

	function timeout() {
		interval.cancel(state.idle);
		interval.cancel(state.timeout);

		state.idling = true;
		state.running = false;
		state.countdown = 0;

		eventBus.broadcast("security:IdleTimeout");
	}

	function changeOption(self, fn, value) {
		const reset = self.running();

		self.unwatch();
		fn(value);
		if (reset) {
			self.watch();
		}
	}

	function getExpiry() {
		const obj = storage.get("expiry");
		return obj && obj.time ? new Date(obj.time) : null;
	}

	function setExpiry(date) {
		if (!date) {
			storage.remove("expiry");
		} else {
			storage.set("expiry", {
				id,
				time: date
			});
		}
	}

	const svc = {
		_options() {
			return options;
		},
		_getNow() {
			return new Date();
		},
		getIdle() {
			return options.idle;
		},
		getTimeout() {
			return options.timeout;
		},
		setIdle(seconds) {
			changeOption(this, setIdle, seconds);
		},
		setTimeout(seconds) {
			changeOption(this, setTimeout, seconds);
		},
		isExpired() {
			const expiry = getExpiry();
			return expiry !== null && expiry <= this._getNow();
		},
		running() {
			return state.running;
		},
		idling() {
			return state.idling;
		},
		watch(noExpiryUpdate) {
			interval.cancel(state.idle);
			interval.cancel(state.timeout);

			// calculate the absolute expiry date, as added insurance against a browser sleeping or paused in the background
			const timeoutValue = !options.timeout ? 0 : options.timeout;
			if (!noExpiryUpdate) {
				const expiryPeriod = (options.idle + timeoutValue) * 1000;
				setExpiry(new Date(new Date().getTime() + expiryPeriod));
			}

			if (state.idling) {
				// clears the idle state if currently idling
				toggleState();
			}

			state.running = true;
			state.idle = interval(toggleState, options.idle * 1000, 0, false);
		},
		unwatch() {
			interval.cancel(state.idle);
			interval.cancel(state.timeout);

			state.idling = false;
			state.running = false;
			setExpiry(null);
		},
		interrupt(anotherTab) {
			if (!state.running) {
				return;
			}
			if (options.timeout && this.isExpired()) {
				timeout();
				return;
			}

			// note: you can no longer auto resume once we exceed the expiry; you will reset state by calling watch() manually
			const notIdling = options.autoResume === "notIdle" && !state.idling;
			if (anotherTab || options.autoResume === "idle" || notIdling) {
				this.watch(anotherTab);
			}
		}
	};

	const lastMove = {
		clientX: null,
		clientY: null,
		swap(event) {
			const last = {
				clientX: this.clientX,
				clientY: this.clientY
			};
			this.clientX = event.clientX;
			this.clientY = event.clientY;
			return last;
		},
		hasMoved(event) {
			const last = this.swap(event);
			if (this.clientX === null || event.movementX || event.movementY) {
				return true;
				// eslint-disable-next-line eqeqeq
			} else if (last.clientX != event.clientX || last.clientY != event.clientY) {
				return true;
			}
			return false;
		}
	};

	function addEventListeners(element, events, callback, useCapture) {
		const eventList = events.split(" ");
		for (let i = 0; i < eventList.length; i++) {
			element.addEventListener(eventList[i], callback, useCapture);
		}
	}

	if (options.interrupt) {
		const htmlElement = document.querySelector("html");
		const callback = (event) => {
			if (event.type === "mousemove" && event.originalEvent && event.originalEvent.movementX === 0 && event.originalEvent.movementY === 0) {
				// Fix for Chrome desktop notifications, triggering mousemove event.
				return;
			}

			if (event.type !== "mousemove" || lastMove.hasMoved(event)) {
				svc.interrupt();
			}
		};
		addEventListeners(htmlElement, options.interrupt, callback, false);
	}

	if (options.windowInterrupt) {
		const fn = () => {
			svc.interrupt();
		};
		addEventListeners(window, options.windowInterrupt, fn, false);
	}

	const wrap = (event) => {
		if (event.key === "ngIdle.expiry" && event.newValue && event.newValue !== event.oldValue) {
			const val = JSON.parse(event.newValue);
			if (val.id === id) { return; }
			svc.interrupt(true);
		}
	};

	if (window.addEventListener) {
		window.addEventListener("storage", wrap, false);
	} else {
		window.attachEvent("onstorage", wrap);
	}

	return svc;
};
export default builder;
