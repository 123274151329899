export default function (authConfig, loginState, location, authLogger, timeout, eventBus, document) {
	let frameInstalled = false;
	function installRefreshIFrame() {
		if (!frameInstalled) {
			frameInstalled = true;
			document.querySelector("body").insertAdjacentHTML("beforeend", "<iframe id=\"refresh_iframe\" name=\"refresh_iframe\" style=\"visibility: hidden; display: none;\">");
		}
	}

	function openLoginPageInBackground(newLogin) {
		installRefreshIFrame();
		const refreshIFrame = document.querySelector("#refresh_iframe");
		refreshIFrame.src = authConfig.loginUrlFor(newLogin, location.absUrl());
	}

	function refreshTokenUsingIFrame() {
		if (!loginState.isInProgress()) {
			authLogger.logInfo("Starting token refresh in background.");

			const newLogin = loginState.startRefresh();
			openLoginPageInBackground(newLogin);

			// if refresh takes longer we assume that it's failed
			timeout(() => {
				if (loginState.isCurrentLoginAbout(newLogin)) {
					authLogger.logFailedLogin("Token refresh in background failed to succeed in a timeout.", newLogin);
					loginState.stopLogin();
				}
			}, authConfig.refreshTokenTimeout * 1000);
		}
	}

	/* Responding on an event sent by login page - if login page is opened, then automatic token refresh is failed */
	function markRefreshAsFailedIfLoginPageOpened() {
		const currentLogin = loginState.currentLogin();
		if (currentLogin.inProgress && currentLogin.isRefresh) {
			authLogger.logFailedLogin("Token refresh in background failed - login page was opened in iframe.", currentLogin);
			loginState.stopLogin();
		}
	}

	this.install = () => {
		eventBus.on("security:loginPageOpened", markRefreshAsFailedIfLoginPageOpened);
	};
	this.startRefresh = refreshTokenUsingIFrame;
}
