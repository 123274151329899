
export default function (authContext, authConfig, location, consoleLogger, trackErrorFn, browserId) {
	this.logInfo = (message) => {
		consoleLogger.info(message);
	};
	this.logFailedLogin = (message, failedLogin) => {
		this.logError(`${message} Failed login: ` +
			`state: ${failedLogin.state}, ` +
			`nonce: ${failedLogin.nonce}, ` +
			`startTime: ${failedLogin.startTime}, ` +
			`method: ${failedLogin.method}.`);
	};
	this.logError = (message) => {
		try {
			const tokenExpirationDate = !authContext.getExpValue() ? "[NO TOKEN]" : new Date(authContext.getExpValue() * 1000).toISOString();
			const username = authContext.isLogged() ? authContext.username() : "(not logged)";

			const fullMessage = `${message} ` +
				`customer: '${authConfig.customer}', ` +
				`user: '${username}', ` +
				`token_expiration: ${tokenExpirationDate}, ` +
				`browser_id: ${browserId.get()}, ` +
				`url: ${location.url()}`;
			trackErrorFn(fullMessage);
			consoleLogger.error(fullMessage);
		} catch (e) {
			consoleLogger.error(e);
		}
	};
}
