import ReloginModal from "./reloginModal";
import RefreshFrame from "./refreshFrame";

export default function (authContext, authConfig, activeTab, loginState, location, authLogger, interval, timeout, eventBus, document) {
	const reloginModal = new ReloginModal(authConfig, authContext, loginState, authLogger, location, interval, eventBus, document);
	const refreshFrame = new RefreshFrame(authConfig, loginState, location, authLogger, timeout, eventBus, document);

	/**
	 * Two minutes before expiration of access token we are trying to refresh it in the background.
	 *
	 * Opens a login page in iframe which will automatically redirect by to our page
	 * with new token, which will be stored in the local storage.
	 * Other methods (using async call) will not work because of same-origin policy.
	 */
	function tokenRefreshInBackground() {
		if (authContext.isLogged() && activeTab.isTabActive()) {
			if (authContext.tokenWillExpireIn(authConfig.refreshTokenSecBeforeExpiration) && !authContext.tokenWillExpireIn(authConfig.reloginSecBeforeExpiration)) {
				refreshFrame.startRefresh();
			}
		}
	}

	/**
	 * Just before expiration of access token we are trying to refresh it by showing login screen.
	 * It opens a login page in a modal, so user can provide credentials again
	 */
	let tokenRefreshModalOpened;
	function tokenRefreshByLogin() {
		if (authContext.isSessionNotExpired() && !tokenRefreshModalOpened) {
			if (authContext.tokenWillExpireIn(authConfig.reloginSecBeforeExpiration)) {
				tokenRefreshModalOpened = true;
				authLogger.logError("Opening relogin modal since token refresh didn't worked.");
				reloginModal.open(() => {
					tokenRefreshModalOpened = false;
				});
			}
		}
	}

	let tokenRefreshErrorLogged = false;
	function logRefreshTokenIssues() {
		if (activeTab.isTabActive()) {
			if (authContext.hasExpiredToken() && !tokenRefreshErrorLogged) {
				authLogger.logError("Token expired too early. It seems that refresh of the token didn't work.");
				tokenRefreshErrorLogged = true;
			}
		}
	}

	this.start = () => {
		interval(logRefreshTokenIssues, 10 * 1000);
		interval(tokenRefreshInBackground, 10 * 1000);
		interval(tokenRefreshByLogin, 10 * 1000);

		refreshFrame.install();
	};
}
